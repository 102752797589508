:root {
    --padding: 1%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 10vh;
    
    /* change settings for headings */
    --header-bg-color: rgba(255,255,255,0.7);
    --header-padding: 1.5em 0 0;
    
    
    /* change colors for topmenu */
    --topmenu-bg: #FB9C15;
    --topmenu-color: white;
    --topmenu-li-a-padding: 10px 15px;
    
        /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 14px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;

}

@import "/cssmodules/normalize.css";
@import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/OpenSans.css";

/*
@import "/theme/intendit/css/modules/photoswipe.css";
@import "/theme/intendit/css/modules/animate.css";
*/

/* general
---------------------------------------------------------*/

section {
    min-height:50vh;
    padding:5% 0;
}

.white {
    background-color:white;
}

.gray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}
.black {
    background-color:#000;
}

#menu-icon {
    z-index:100;
}

#menu-icon span {
    border:2px solid black;
}

.page {
    min-height:80vh;
    margin-top:120px;
} 

/* header
---------------------------------------------------------*/
header {
    background:white;
    text-transform:none;
    padding:var(--header-padding);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
    position:fixed;
    top:0;
    z-index:100;
    width:100%;
}

.startsida header {
    background:var(--header-bg-color);
}

header .container {
    padding:0 0.5em;
}

header a {
    color:blck;
}

.menu li {
    margin:0;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    color:black;
    margin-right:1px;
}

.menu li a:hover, .menu .active a {
    background: var(--topmenu-bg);
    color:var(--topmenu-color);
}

.menu .dropdown ul {
    background:#fff;
    z-index:100;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}

.logo a img {
    position:absolute;
    top:0;
}

/* slider
---------------------------------------------------------*/

.slick-slider {
    margin:0 auto;
    position: relative;
}
.startsida .slick-track, .slickbg, .slickimg {
    height: 50vh;
}

.slick-slide, {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.slickimg {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    
}

.slider-content .container {
    max-width:550px;
}

.slider-content span {
    display:block;
}

.slider-content span {
    display:block;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    text-align:center;
    font-weight:700;
    color:white;
}

.slider-heading {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

.slider-content-txt {
    font-weight:normal;
    color:white;
    font-size: var(--base-font-size);
    font-size:1.7rem;
}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    font-size:5rem;
}

.slick-dots {
    bottom:0px;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color:#F7FA49;
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background:#F7FA49;
    display:block;
}

.slick-dots li {
    border:#F7FA49 solid 1px;
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
}


/* Startbox / zoomboxar
---------------------------------------------------------*/

.startbox {
    text-decoration:none;
    color:#111;
}

.startbox article {
    margin-bottom:1.5em;
}

.startbox-wrapper {
    -moz-box-shadow: 0 0 3px #ccc;
    -webkit-box-shadow: 0 0 3px #ccc;
    box-shadow: 0 0 3px #ccc;
    border:#fff solid 1px;
}

.pop-img img {
    width:100%;
    border-bottom:#80451D solid 0px;
}

.startbox-inner {
    padding:0 1.5em;
    display:block;
    position:relative;
}

.block-content {
    color:#111;
}

.block-rub h2 {
    color:#251408;
    font-size:2.0rem;
    font-weight:bold;
}

.boxLink {
    padding:1.5em;
}

.zoombox { height:30vw; overflow:hidden; position:relative; color:#fff; text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4); margin-bottom:0; box-shadow: 0px 0px 0px 2px #fff;}
.zoombox a { -webkit-transition: color 3s ease-in-out 0s; transition: color 3s ease-in-out 0s;}
.zoombox a:hover { color: rgba(255, 255, 255, 1); }
.zoombox_innerdiv {height:100%; width:100%; background-position: 50% 50%; background-size:cover; transform: scale(1); position:absolute; transition: transform 3s ease-in 0s;}
.zoomboxar > a:hover .zoombox_innerdiv {transform: scale(1.2);transition: transform 3s ease-in 0s;} 
.zoombox_textdiv {height:100%; width: 100%; overflow:hidden; margin:0;  padding: 0px 2em; text-align:center; position:absolute;} 

.zoombox h1, .zoombox h2 {padding-bottom: 35px; position: relative; text-align: center; font-size:2.2rem; text-shadow:0px 0px 20px rgba(0, 0, 0, 0.4); font-weight:700; }
.zoombox:hover h1:after, .zoombox:hover h2:after {opacity:1; transition:0.5s ease-in 0s; }
.zoombox h1 span, .zoombox h2 span { display:block; font-size:1.2rem; padding-top:0.5em;}

.zoombox h1, .zoombox h2 { color: rgba(255, 255, 255, 0.4); -webkit-transition: color 1s ease-in-out 0s; transition: color 1s ease-in-out 0s; display:block;}
.zoombox h1:hover, .zoombox h2:hover { color: rgba(255, 255, 255, 1);}



/* block2
---------------------------------------------------------*/
section.start-block-wrappe2 {
    padding:1.5em 0;
    min-height:5vh;
}

.block2-content {
    text-align:center;
    max-width:850px;
    margin:0 auto;
    width:100%;
}

/* block3
---------------------------------------------------------*/
section.start-block-wrapper3 {
    padding:1.5% 0;
    min-height:5vh;
}

.start-block-wrapper3 .container > h2 {
    text-align:center;
}

/* block4
---------------------------------------------------------*/
.start-block-wrapper4 {
    color:white;
}

.start-block-wrapper4 h3 {
    color:white;
    font-weight:bold;
}

.start-block-wrapper4 button {
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

/* page
---------------------------------------------------------*/

.inner-content  ul {
    list-style-type: none;
    margin-top:0;
    padding:0;
}

.inner-content ul li {
    padding-left: 1.2em;
    padding-bottom:0.4em;
    
}

.inner-content ul li:before {
  content: "\f046"; /* FontAwesome char code inside the '' */
  font-family: FontAwesome; /* FontAwesome or whatever */
  display: inline-block;
  width: 1.2em; /* same as padding-left set on li */
  margin-left: -1.2em; /* same as padding-left set on li */
}

.block-contentgallery ul {
    list-style:none;
    padding:0;
}

.block-contentgallery ul * {
    margin-bottom:0;
}

.block-contentgallery ul li a img {
    -moz-box-shadow: 0 0 3px rgba(68, 66, 66, 0.48);
    -webkit-box-shadow: 0 0 3px rgba(68, 66, 66, 0.48);
    box-shadow: 0 0 3px rgba(68, 66, 66, 0.48);
    border: #fff solid 4px;
}

.contact-heading {
    display:block;
    font-size:1.2rem;
}

.contact-info ul {
    list-style:none;
    margin:0.5em 0 0 0;
    padding:0;
}
.contact-info li {
    padding-bottom:0.3em;
}

hr {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0;
    border: none;
    height: 1px;
    background-color: #B0945C;
    clear: both;
}

.fa-map-marker:before {
    font-size:3rem;
}

/* Footer
---------------------------------------------------------*/

footer {
    color:white;
}

footer a {
    color: white;
    text-decoration:none;
}

footer a:hover {
    color:#F28D1A;
}

.socialamedia a {
    font-size:2.0rem;
    padding-right:0.5em;
}

footer h4 {
    font-size:1.8rem;
    color:white;
}

.created-by {
    background:#100F0F;
    border-top:#151414 solid 1px;
    padding:0.5em 0;
}

.created-by .container, .intenditfooter {
    padding:0;
    margin:0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
    color:#565656;
}

.seach input {
    max-width:70%;
    display:inline-block;
}

footer .container > div {
    margin-bottom:1.5em;
}

.contact-info span, .ovrig-info span {
    display:block;
}

.contact-info {
    margin-top:0.5em;
}

.id06 span {
    margin-top:4%;
    background:white;
    border-radius:5px;
    padding:0.5em;
    text-align:center;
    display:inline-block;
}

/* other
---------------------------------------------------------*/

.boltform textarea {
    min-height:120px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color:#222;
}

.button, button, input[type=reset], input[type=submit] {
    margin:0;
    background:#F28D1A;
    border:#F28D1A solid 1px;
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.button:hover, input[type=reset]:hover, input[type=submit]:hover {
    background:#000;
    border:#000 solid 1px;
    color:white;
}

button[type=submit], input[type=submit] {
    background:#F28D1A;
}

button[type=submit]:hover, input[type=submit]:hover {
    background:#000;
    border:#000 solid 1px;
    color:white;
}

a {
    color:#000;
    text-decoration:none;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color:#222;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

body {
    font-family: OpenSans;
    font-weight: 400;
    color:#282d32;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

.container {
    max-width: 1100px;
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
}

/* Give map a height and it's images no max-width */
.map-canvas {
    height: 50vh;
}

.map-canvas img {
    max-width: none;
}

h1, h2, h3, h4, h5, h6 {
    color:#000;
    margin-top:0;
    margin-bottom:0.3em;
}

h1 {
      font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h2 {
  font-size: calc(1 * var(--scale-factor)rem);

}

h3 {
      font-size: 1.2rem;
    
}

p {
    margin-top:0;
}

/* Make FB widget responsive */
.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe[style],
.twitter-timeline {
    width: 100% !important;
}

/* Example media queries*/
@media (max-width: 400px) {}

@media (max-width: 550px) {
    .zoombox, .zoomkatboxar, .zoomkatboxar .zoombox {
        height:70vw;
    }
    .homepage header {
        background:transparent;
    }
}

@media (max-width: 640px) {
    .logo a img {
        max-width:70%;
        position:relative;
    }
    header {
        padding:0;
    }
    .slickimg {
        align-items: flex-end;
    }
    .slider-heading {
        font-size:2rem;
    }
    .slider-content-txt {
        font-size:1.3rem;
    }
}

@media (max-width: 750px) and (min-width: 551px ) {
    .zoombox, .zoomkatboxar, .zoomkatboxar .zoombox {
        height:40vw;
    }
}

@media (max-width: 800px) {
    .bildspel_titel .container {
        font-size:1.1em;
    }
    .zoombox h1, .zoombox h2 {
        font-size:1.7rem;
    }   
}

@media (max-width:var(--breakpoint-menu)) {
    .menu.show, .menu {
        background:#fff !important;
        box-shadow: 0 4px 2px -2px gray;
    }
    .menu li, .menu li ul, .menu li:hover ul {
        margin:0;
    }
    .menu li a {
        color: black;
    }
}
@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
}

@media (max-width: var(--breakpoint-xs)) {
    h1 {
        font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
}
